.navbar {
  position: fixed;
  top: 0;
  height: 5rem;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 10;
  color: white;
}

.navbar_change {
  background: rgba(255, 255, 255);
  backdrop-filter: blur(20px);
  color: black;
}

.nav_active {
  color: theme("colors.primary-deep");
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .hamburger {
    z-index: 2;
    position: absolute;
    right: 10px;
  }

  .contact_buttons {
    display: none;
  }

  .nav_items {
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;
    clip-path: circle(100px at 90% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 0.4s ease-out;
    color: #ffffff;
  }

  .responsive_navbar {
    position: absolute;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: rgba(7, 98, 40, 0.8);
    backdrop-filter: blur(10px);
    width: 100%;
    gap: 2rem;
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
    transition: all 0.4s ease-out;
    color: #ffffff;
  }

  .responsive_navbar li {
    color: #ffffff;
    width: 100%;
  }
}
