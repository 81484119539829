.hero_section {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../images/consult.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

svg.wave {
  position: absolute;
  bottom: -50px;
  color: green;
}

@media screen and (max-width: 960px) {
  svg.wave {
    display: none;
  }
}
