.service_card {
  background-color: #ffffff;
  background-image: radial-gradient(
    circle at 50% 240%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-size: 100% 200%;
  /*trasition effect for background*/
  transition: background-color ease-in-out 0.3s;
}

.service_card:hover {
  background-position: 100% 100%;
  color: white !important;
}

.service_card:hover div span {
  background-position: 100% 100%;
  color: white !important;
}
