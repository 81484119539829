.pagelayout {
  /* background: url(../images/background.webp); */
  background: #2c4623;
  /* background-color: #5c8431; */
  /* background-color: #2e51ed; */

  background-image: radial-gradient(
    circle at 50% 170%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(20px);
  padding-top: 60px;
  height: 46vh;
}
