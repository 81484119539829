@tailwind base;
@tailwind components;
@tailwind utilities;

/* ################################################### */
/* Custom scrollbar */
/* ################################################### */
/* Firefox */
body::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #e1e1e2; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid fafbff; /* creates padding around scroll thumb */
}

:root {
  --primary-color: #347d39;

  --text-color: #323234;

  --primary-linear: linear-gradient(
    90.05deg,
    #7171db -43.75%,
    #2e51ed 45.9%,
    #5f32c0 123.14%
  );
}

* {
  margin: 0px;
  padding: 0px;
  overflow-x: unset;
  box-sizing: border-box;
}

body {
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.container_fluid {
  padding-left: 130px;
  padding-right: 130px;
  width: 100%;
}

.section {
  padding-top: 20px;
  padding-bottom: 100px;
  width: 100%;
}

.shadow {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.top-shadow {
  height: auto;
  max-width: 540px;
  background-color: #fff;
  border: 2px solid #efefef;
  overflow: hidden;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.16);
}

/* ===================================== */
@media screen and (max-width: 960px) {
  .container_fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section {
    padding-top: 20px;
    padding-bottom: 70px;
    width: 100%;
  }
}

.main_button {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #5c8431;
  background-image: radial-gradient(
    circle at 50% 240%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  transition: background-color 0.3s;
  display: flex;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.main_button:hover {
  transform: translateY(-5px);
}

/* For Whatsapp Icon */
.styles-module_whatsappButton__tVits {
  right: 6rem !important;
  bottom: 1.3rem !important;
}

/* For Swiper */
/* style for swiper button */
.swiper-pagination-bullet {
  width: 0.5rem !important;
  height: 0.375rem !important;
  background-color: #6c6ce5 !important;
  opacity: 1 !important;
  /* translate: 0px 0.88rem; */
}

/* .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullet {
  margin: 0 0.2rem !important;
} */

.swiper-pagination-bullet-active {
  width: 1.2rem !important;
  height: 0.375rem !important;
  border-radius: 0.5rem !important;
}
